@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital@0;1&family=Marck+Script&family=Montserrat:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

:root {
  --primary-text-color: #1d1b1b;
  --light-white: #fffbf3;
  --gold: #b8860b;
  --pink: #ff7d85;
  --dirty-pink: #a05e5c;
  --grey: #e0e6ea;
  --light-pink: #ecdcdd;
  --burger-modal: rgba(236, 220, 221, 0.4);
  --header-border: rgba(160, 94, 92, 0.3);
  --header-line: #d9d9d9;
  --footer-black: #000;
  --viber: #665cac;
  --whatsapp: #25d366;
  --telegram: #0088cc;

  --secondary-font: "Marck Script", sans-serif;
  --title-font: "Lato", sans-serif;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: var(--primary-text-color);
  font-family: var(--title-font);
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-white);
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
  margin-top: 0;
  margin-bottom: 0;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

img {
  display: block;
}

button {
  cursor: pointer;
  background-color: inherit;
}

section {
  max-width: 375px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  section {
    max-width: 768px;
  }
}

@media (min-width: 1280px) {
  section {
    max-width: 1280px;
  }
}
